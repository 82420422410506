<template>
  <div>

    <h1 class="text-2xl font-bold mb-5">Bank Account users</h1>

    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :ajax="true"
        :ajaxPagination="true"
        :url="users.url"
        :columns="users.columns"
        :loading="users.loading"
        :query="users.query"
        :onClick="click"
        ref="table"
      />
    </div>

    <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />

  </div>
</template>
<script>
  import moment from 'moment';

  export default {
    name: 'AdminIdentityVerifications',
    data() {
      return {
        users: this.$options.resource([], {
          url: `${this.$baseurl}/admin/personal/identity-verifications`,
          query: '',
          selected: null,
          columns: [
            {
              name: 'id',
              th: 'User ID',
            },
            {
              name: 'name',
              th: 'User',
              render: (user) => `${user.name} ${user.last_name}`
            },
            {
              name: 'bvn',
              th: 'BVN',
              render: (user) => user.profile?.bvn
            },
            {
              name: 'progress',
              th: 'Progress',
              render: (user) => {
                return this.progressIcons(user);
              }
            },
            {
              name: 'status',
              th: 'Status',
              render: (user, status) => {
                return this.statusBadge(status, user);
              }
            },
            {
              name: 'attended_at',
              th: 'Date Acted On',
              render: (user) => {
                return moment(user.bvn_bypassed_at).fromNow();
              }
            }
          ]
        })
      }
    },
    methods: {
      click(user) {
        this.users.selected = user;
        this.$refs.userModal.open();
      },
      reloadTable() {
        this.$refs.table.loadAjaxData()
      },
      userDeleted() {
        this.reloadTable();
      },
      userModalClosed() {
        this.users.selected = null;
      },
    }
  }
</script>
